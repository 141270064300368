
function bindLetter(){
	if (!$('.app-decor-title').length) {
		return false;
	}

	$('.app-decor-title').find('.app-decor-title__letter').css({'display' : 'none'});
	$('.app-decor-title').find('.app-letter').css({'display' : 'flex'});
	
}

/**
 * Инит модуля
 */
function init() {
	bindLetter();
}

module.exports = {
	init,
};
